var objectValues = require('object-values')
var reverseObject = require('reverse-object-order');
var raw = require('bel/raw');

var footnotes = require('markdown-it-footnote');
const md = require('markdown-it')();
const { html5Media } = require('markdown-it-html5-media');
md.use(footnotes);
md.use(html5Media);

var html = require('choo/html')

module.exports = view

function view (state, emit) {

  var page = state.page || { }

  return html`
  <body>
    ${ header() }
      <div class="wrapper">
       <div class="main">
         <div class="post-title">${page.title ? raw(md.render(page.title)) : ''}</div>
         <div>
         ${
           raw(
             md
             .render(page.text)
             .replace(/\[(.*?)\]/g, '($1)')
             //.replace(/href="#(.*?)/g, `href="/#$1`)
             || 'No content'
           )
           }

         </div>
         <div class="post-date">${page.date ? raw(md.render(page.date)) : ''}</div>
          ${ page.pages ? pages() : '' }

         </div>
        </div>
        <script>

        var z = document.getElementsByClassName("html5-audio-player");

        for (let item of z) {
          item.parentNode.insertBefore(player(item),item);
        }

        function player(x) {

          x.style.display = "none";

          var newDiv = document.createElement("div");
          newDiv.classList.add('player');

          var barFrame = document.createElement("div");
          var bar = document.createElement("div");
          barFrame.classList.add('barFrame');
          bar.classList.add('bar');
          barFrame.appendChild(bar);
          newDiv.appendChild(barFrame);

          barFrame.addEventListener('click', function(ev) {
              var ratio = ev.offsetX / barFrame.offsetWidth;
	            x.currentTime = ratio * x.duration;
          });

          var progress = document.createElement("p");
          progress.classList.add("progress");
          progress.innerHTML = "00:00";
          newDiv.appendChild(progress);

          x.ontimeupdate = function prog() {

            var time = Math.floor(x.currentTime);
            var minutes = Math.floor(time / 60);
            var seconds = time - (minutes * 60);

            function str_pad_left(string,pad,length) {
                return (new Array(length+1).join(pad)+string).slice(-length);
            }

            var finalTime = str_pad_left(minutes,'0',2)+':'+str_pad_left(seconds,'0',2);

            progress.innerHTML = finalTime;

            var w = x.currentTime / x.duration * 100;

            bar.style.width = (w += "%");

          };

          var playButton = document.createElement("div");
          playButton.classList.add("pbutton");
          playButton.innerHTML = "play";
          playButton.onclick = function play() {
            playButton.style.fontStyle = "italic";
            pauseButton.style.fontStyle = "normal";
            x.play()
          };
          newDiv.appendChild(playButton);

          var pp = document.createElement("div");
          pp.classList.add("pslash");
          pp.innerHTML = "/";
          newDiv.appendChild(pp);

          var pauseButton = document.createElement("div");
          pauseButton.classList.add("pbutton");
          pauseButton.innerHTML = "pause";
          pauseButton.onclick = function pause() {
            pauseButton.style.fontStyle = "italic";
            playButton.style.fontStyle = "normal";
            x.pause()
          };
          newDiv.appendChild(pauseButton);

          return newDiv;

        };

        </script>

  </body>
  `

  function pages () {
        return objectValues(reverseObject(page.pages)).map(function (subpage) {
            return html`
                <a href="${subpage.url}">
                ${subpage.name.split('_')[1]}
                </a><br>
                `
        })
    }

    function header() {
      return html `
        <div class="headerWrapper">
          <a class="mono" href="/archive">↖ text</a>
            <div class="menu">
              <header>
                  </header>
            </div>
          </div>
        </div>`
    }
  }
