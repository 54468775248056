var footnotes = require('markdown-it-footnote');
var Markdown = require('markdown-it')
var raw = require('bel/raw')
var md = new Markdown()
  .use(footnotes)
var html = require('choo/html')
var menu = require('./menu')
var Page = require('nanopage')

module.exports = view

function view(state, emit) {

  var page = state.page || {}

  return html `
    <body>
      ${ menu(state) }
        <div class="wrapper">
         <div class="main">
            <div class="post-title">${page.title ? raw(md.render(page.title)) : ''}</div>
            <div> ${raw(md.render(page.text) || 'No content')}</div>
            ${page.pages ? pages() : ''}
          </div>
        </div>
     </body>
  `

  function pages() {
    var page = new Page(state)
    var pages = page().children()
    pages = pages.sortBy('url', 'desc')
    pages = pages.toArray()
    return pages.map(function(child) {
      return html `
                <a href="${child.url}">
                ${child.name.split('_')[1]}
                </a><br>
                `
    })
  }

}
