var html = require('choo/html')
var Page = require('nanopage')

module.exports = menu

function menu(state) {

    var page = new Page(state)

    var wrapper = document.createElement('div')
    wrapper.classList.add('headerWrapper')

    var title = document.createElement('a')
    title.classList.add('title')
    title.innerHTML = "ELLEN ARKBRO"
    title.href = "/"
    wrapper.appendChild(title)

    var pages = page('/').children()
    pages = pages.sortBy('order', 'asc')
    pages = pages.toArray()
    pages.forEach(function(item,index) {
      pages[index] = item.name;
    });

    for (let item of pages) {
      var menuItem = document.createElement('div')
      menuItem.classList.add('m1')
      var link = document.createElement('a')
      link.innerHTML = item
      link.href = "/" + item
      if(page().value('name') === item) {
        link.classList.add('current')
      }
      menuItem.appendChild(link)
      wrapper.appendChild(menuItem)
    }

    return wrapper;

}
