var html = require('choo/html')
var raw = require('bel/raw')
var footnotes = require('markdown-it-footnote');
var Markdown = require('markdown-it')
var md = new Markdown()
    .use(footnotes)
var menu = require('./menu')
var Page = require('nanopage')

module.exports = view

function view(state, emit) {

    var page = state.page || {}

    return html `
  <body>
    <style>
    p {
      margin-bottom: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    tr {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    </style>
        ${ menu(state) }
        <div class="wrapper">
          <div class="main">
            ${ events() }
          </div>
        </div>
  </body>`

    function events() {

        var page = new Page(state)


        var events = page().children()
        events = events.sortBy('name', 'desc')
        events = events.toArray()

        return events.map(function(event) {

            return html `
               <div class="smallTable">
                  <div>
                    <b> ${ event.date } </b>
                    ${ event.title ? raw(md.render(event.title)) : '' }
                    ${ event.names ? raw(md.render(event.names)) : '' }
                    ${ event.venue ? raw(md.render(event.venue)) : '' }
                    ${ event.city ? raw(md.render(event.city)) : '' }
                  </div>
                </div>
    `
        })

        function image(event) {
            return html `<a href=${ event.url }>
      <img src=${ "content" + event.url + "/" + event.image }>
      </a>`
        }


    }

}
