var html = require('choo/html')

module.exports = view

function view (state, emit) {
  return html`
    <body>
      <div class="notFound"> this page does not even not exist </div>
    </body>
  `
}
