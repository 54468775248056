var html = require('choo/html')
var raw = require('bel/raw')
var footnotes = require('markdown-it-footnote');
var Markdown = require('markdown-it')
var md = new Markdown()
    .use(footnotes)
var menu = require('./menu')
var Page = require('nanopage')

module.exports = view

function view (state, emit) {

    var page = state.page || { }

    return html `
      <body>
        ${ menu(state) }
          <div class="wrapper">
           <div class="main">
              <div class="post-title">${page.title ? raw(md.render(page.title)) : ''}</div>
              <div> ${raw(md.render(page.text) || '')}</div>
            </div>
          </div>
       </body>
    `

}
